// Customizable Area Start
import React from "react";
import {
  Grid,
  TextField,
  Box,
  Typography,
  TableCell,
  Checkbox,
  InputAdornment,
} from "@material-ui/core";
import ItemsLibraryController, {
  Props,
  ILibraryItem,
} from "./ItemsLibraryController.web";
import { burger, noCategories, searchIcon } from "./assets";
import withStyles, { CSSProperties } from "@material-ui/core/styles/withStyles";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout";
import { Button } from "reactstrap";
import SectionLoader from "../../../components/src/SectionLoader.web";
import { configJSON } from "./InventoryManagementController";
import { ItemBuilderLibrary } from "../../../../packages/components/src/ItemBuilderLibrary.web";
import { TableVirtuoso } from 'react-virtuoso'
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class ItemsLibrary extends ItemsLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleSearchItemsKeyPress = this.handleSearchItemsKeyPress.bind(this);
    // Customizable Area End
  }

    // Customizable Area Start
    categoriesBlock = (classes: Record<string, string>) => {
      return (
        <>
          {!this.state.searchPerformed && (
            <>
              <Box className={classes.loadingSectionMain} id="loadingSectionMain">
                <Box className={classes.loadingSection} id="loadingSection">
                  <img src={burger} className={classes.burgerImg} id="burgerImg" />
                  <Box className={classes.searchItemInput} id="searchItemInput">
                    <TextField
                      id="searchItemsLibrary"
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={this.state.searchCategory}
                      placeholder={configJSON.searchItemPlaceholder}
                      data-testId="searchItemsInput"
                      className={classes.InputStyle}
                      autoComplete="off"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              className={classes.searchIcon}
                              src={searchIcon}
                              alt="searchIcon"
                            />
                          </InputAdornment>
                        ),
                        classes: { input: classes.input }
                      }}
                      onChange={this.handleChangeSearchInput}
                      onKeyDown={this.handleSearchItemsKeyPress}
                    />
                  </Box>
                </Box>
              </Box>
              <Grid item xs={12} className={classes.bottomButtonContainer} id="grid">
                <Button
                id="cancelButton"
                  variant="contained"
                  className={classes.cancelButton}
                  data-testId="cancelLibraryBtn"
                  onClick={this.navigateToInventoryManagement}
                >
                  <Typography className="Text" id="cancelTypography">{configJSON.cancel}</Typography>
                </Button>
              </Grid>
            </>
          )}
          {this.state.searchPerformed && this.state.noSearchCategories && (
            <Grid container direction="column" id="columnDirection">
              <Typography className={classes.noResultSearchText} id="Typography1">
                {configJSON.noResultFoundText}
              </Typography>
              <Box className={classes.noResultSearch} id="noResultSearchBox">
                <Box className={classes.noResultSearchBar} id="noresultsearchbar">
                  <TextField
                    id="noResultItemsSearch"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={this.state.searchCategory}
                    data-testId="noResultItemsSearchInput"
                    className={classes.InputStyle1}
                    autoComplete="off"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            className={classes.searchIcon}
                            src={searchIcon}
                            alt="searchIcon"
                          />
                        </InputAdornment>
                      ),
                    }}
                    onChange={this.handleChangeSearchInput}
                    onKeyDown={this.handleSearchItemsKeyPress}
                  />
                </Box>
              </Box>
              
              <Box className={classes.categoriesContentMiddle} id="categoriesContentMiddle">
                <Box className={classes.categoriesContent} id="categoriesContent">
                  <Typography className={classes.noCategoriesImg} id="nocategoriesimg">
                    <img src={noCategories} alt="no products" />
                  </Typography>
                  <Typography variant="h5" className={classes.noResultFound}>
                    {configJSON.noResultFoundText}
                  </Typography>
                  <Typography className={classes.noResultFoundText}>
                    {configJSON.noResultFoundTryAgain}
                  </Typography>
                </Box>
              </Box>
              <Grid item xs={12} className={classes.bottomButtonContainer}>
                <Button
                  variant="contained"
                  className={classes.cancelButton}
                  data-testId="cancel-from-no-data"
                  onClick={this.backFromSearchedCategories}
                >
                  <Typography className="Text">{configJSON.cancel}</Typography>
                </Button>
              </Grid>
            </Grid>
          )}
          {this.state.searchPerformed && !this.state.noSearchCategories && (
            <Grid container className={classes.mainGrid}>
              <Grid item xs={12}>
              <Typography className={classes.foundText}>
                {configJSON.weFoundInitials}
                {this.state.categories.length}
                {configJSON.foundMessage}
              </Typography>
              </Grid>
              <Grid item className={classes.mainTable}>
                <TableVirtuoso
                  totalCount={this.state.initCategories.length}
                  style={{ width: "100%", height: "100%" }}
                  fixedHeaderContent={() => (
                    <tr style={{ backgroundColor: "#f8f9fa" }}>
                      <TableCell component="td" className={classes.headerText}>
                        <Checkbox
                          className={classes.checkAllCheckbox}
                          checked={this.state.allChecked}
                          data-testId="checkAllCheckbox"
                          onChange={this.handleSelectAllCheckboxChange}
                        />
                      </TableCell>
                      <TableCell component="td" className={classes.headerText}>
                        {configJSON.itemName}
                      </TableCell>
                      <TableCell component="td" className={classes.headerText}>
                        {configJSON.image}
                      </TableCell>
                      <TableCell component="td" className={classes.headerText}>
                        {configJSON.description}
                      </TableCell>
                      <TableCell component="td" className={classes.headerText}>
                        {configJSON.price}
                      </TableCell>
                      <TableCell component="td" className={classes.headerText}>
                        {configJSON.category}
                      </TableCell>
                    </tr>
                  )}
                  itemContent={(index) => (
                    <ItemBuilderLibrary
                      index={index}
                      category={this.state.initCategories[index]}
                      classes={this.props.classes}
                      allChecked={this.state.allChecked}
                      handleCheckboxChange={this.handleCheckboxChange}
                      handleUpdateCategoriesData={this.handleUpdateCategoriesData}
                      onChangeSelectCategoryInput={this.onChangeSelectCategoryInput}
                    />
                  )}
                />
                <Grid item xs={12} className={classes.bottomButtonContainer}>
                  <Button
                    className={classes.backButton}
                    data-testId="backLibraryBtn"
                    style={{backgroundColor:'#00D65900'}}
                    onClick={this.backFromSearchedCategories}
                  >
                    <Typography className="Text">
                      {configJSON.back}
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.nextButton}
                    style={{backgroundColor:'#00D659'}}
                    data-testId="nextBtnEnable"
                    onClick={this.handleNextButtonWithInput}
                    disabled={this.state.isNextButtonDisabled}
                  >
                    <Typography className="Text">{configJSON.next}</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      );
    };
    // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;

    return (
      <Box className={classes.loadingSearchDiv}>
        {this.state.loadingSearch ? <SectionLoader loading={true} /> : this.categoriesBlock(classes)}
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const useStyles: Record<string, CSSProperties> = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  dataText: {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#3C3E49",
    "& .ImageBox":{
      border:'1px solid #E2E8F0',
      display:'flex',
      alignItems:'center' as const,
      justifyContent:'center',
      width:44,
      height:44
    },
    "& .itemImage": {
      height: 36,
      width: 36,
      borderRadius: "4px",
      objectFit: "cover" as const,
    },
    "&.description": {
      width: "40%",
      minWidth:400,
      maxWidth: "40%",
      textAlign: "left" as const,
    },
    "&.category":{
      minWidth: "122px",
      textAlign: "left" as const,
    },
    "&.itemName":{
      minWidth: "200px",
      textAlign: "left" as const,
    }
  },
  headerText: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#363636",
  },
  bottomSection: {
    borderTop: "1px solid lightGray",
    marginTop: "11%",
    marginBottom: "2%",
  },
  input: {
    color: 'black', 
    '&::placeholder': {
      color: '#676B7E',
    },
  },
  searchIcon: {
    height: "24px",
    width: "24px",
  },
  searchBar: {
    width: "48%",
    background: "#fff",
  },
  burgerImg: {
    width: "10%",
    marginBottom: "30px",
  },
  cancelButton: {
    background: "#00D659",
    textTransform: "none" as const,
    height: "100%",
    boxShadow: "none",
    border: "none",
    color: "black",
    padding: "20px 48px",
    borderRadius: "3px",
    marginRight: 16,
    marginLeft: 12,
    "&:hover": {
      background: "#00D659",
      color: "black",
    },
    "& .Text": {
      fontFamily: "Rubik",
      lineHeight: "24px",
      fontSize: "16px",
      fontWeight: 500,
      color: "#3C3E49",
    },
  },
  nextButton: {
    background: "#00D659",
    textTransform: "none" as const,
    height: "100%",
    boxShadow: "none",
    padding: "20px 48px",
    borderRadius: "3px",
    marginRight: 16,
    marginLeft: 12,
    border: "none",
    "&.disabled":{
      background: "#00D659",
    },
    "&:hover": {
      background: "#00D659",
    },
    "& .Text": {
      fontFamily: "Rubik",
      lineHeight: "24px",
      fontSize: "16px",
      fontWeight: 500,
      color: "#3C3E49",
    },
  },
  backButton: {
    textTransform: "none" as const,
    padding: "10px 26px",
    "& .Text": {
      fontFamily: "Rubik",
      lineHeight: "24px",
      fontSize: "16px",
      borderRadius: "3px",
      fontWeight: 400,
      color: "#3C3E49",
      textDecoration: "underline" as const,
    },
    "&:hover": {
      background: "#fff",
    },
    backgroundColor: "#fff",
    border: "none",
  },
  bottomButtonContainer: {
    position: "fixed" as const,
    right: 0,
    bottom: "0%",
    height: 64,
    width: "100%",
    borderLeft: "2px solid #ECEDF0",
    borderTop: "2px solid #ECEDF0",
    backgroundColor: "#FFF",
    display: "flex",
    justifyContent: "flex-end" as const,
  },
  InputStyle: {
    borderRadius: "8px",
    color: "black",
    "& input": {
      fontFamily: "Inter",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "24px",
      padding: "20px 12px 20px 0px",
    },
    "& fieldset": {
      border: "1px solid #D0D2DA",
      top: "0px",
    },
  },
  InputStyle1: {
    borderRadius: "8px",
    color: "black",
    "& input": {
      fontFamily: "Rubik",
      fontSize: "19px",
      fontWeight: "400",
      lineHeight: "24px",
      height: "41px",
      padding: "5px 0px",
    },
    "& fieldset": {
      border: "1px solid #D0D2DA",
      top: "0px",
    },
  },
  categoriesContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    marginTop: "2%",
    height: "230px",
    border: "2px solid #ECEDF0",
    width: "95%",
    background: "#fff",
    padding: "0px"
  },
  noResultFound: {
    fontFamily: "Rubik",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "26px",
    letterSpacing: "-0.02em",
    marginBottom: "5px",
    color: "#000000",
  },
  noResultFoundText: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    marginBottom: "10px",
    color: "#83889E",
    width: "395px",
  },
  foundText: {
    textAlign: "center",
    margin: "12px 0",
    color: "#676B7E",
    fontSize: "14px",
    fontWeight: 400,
  },
  checkAllCheckbox: {
    "&$checked": {
      color: "#364F6B",
    },
    "&:hover": {
      background: "none",
    },
    "&.Mui-checked": {
      color: "#6200EA",
    },
  },
  tableInput: {
    "& .MuiOutlinedInput-input": {
      padding: "10px",
    },
    "& .MuiOutlinedInput-multiline": {
      padding: 6,
    },
    "& .MuiInputBase-multiline": {
      padding: 0,
    },
    "&.description": {
      width: "100%",
    },
  },
  tableInputPrice: {
    "& .MuiOutlinedInput-input": {
      padding: "10px",
    },
    width: "90px",
  },
  mainLoadingMsg: {
    fontSize: "24px",
    fontWeight: 700,
  },
  subLoadingMsg: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#646464",
  },
  loadingSectionMain:{
    height: "100vh",
    display: "flex",
    justifyContent: "center",
  },
  loadingSection: {
    height: "226px",
    width:760,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent:'center',
    marginTop:'8%',
    backgroundColor:'#FFFFFF',
    borderRadius:10
  },
  loadingImg: {
    height: "76px",
    marginBottom: "30px",
  },
  noResultSearch: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  categoriesContentMiddle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  noResultSearchText: {
    fontSize: "26px",
    fontWeight: 500,
    color: "#3C3E49",
    margin: "25px 0 40px 35px",
  },
  mainGrid: {
    overflowY: "auto" as const,
    height: '100vh',
    display:'block',
    "&.LoadingGrid":{
        display:'flex',
        justifyContent:'center'
    }
  },
  mainTable: {
    display: 'flex',
    justifyContent: 'center',
    padding: 16,
    width: '100%',
    height: "100%",
    paddingBottom: 148,
    "& .TCElement":{
      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
          display: 'none',
        }
    },
    "& table": {
      width: "100%"
    },
    "& tr": {
      verticalAlign: "top",
      border: "2px solid #ECEDF0"
    },
  },
  tableBody: {
    backgroundColor: "#fff",
  },
  tableRow: {
    border: "2px solid #ECEDF0",
    verticalAlign:"top",
  },
  searchItemInput: {
    width: "88%",
    background: "#fff",
  },
  noResultSearchBar: {
    width: "95%",
    background: "#fff",
    padding: "0px"
  },
  noCategoriesImg: {
    marginBottom: "10px",
  },
  loadingSearchDiv: {
    overflowY: "auto",
    height: "100vh",
  },
  SideBar: {
    zIndex: 1,
  },
  addCategoryInput: {
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19px",
    color: "#5500CC",
    cursor: "pointer",
    border: "none",
    backgroundColor: "transparent",
    "&:hover": {
      background: "#fff",
      color: "#5500CC",
    },
    padding: "0%"
  },
  // Customizable Area Start
};

export default withLayout(withStyles(useStyles)(ItemsLibrary));
// Customizable Area End
