// Customizable Area Start
// @ts-nocheck
import * as React from 'react';
import { Nav as NavContainer, NavItem, Button } from 'reactstrap';
import RenderLink from './RenderLink';
import './assets/css/style.css';
import withToast from "../HOC/withSnackBar.Web";
import { getStorageData } from "../../../../framework/src/Utilities";
import NavController from './NavController.web'
export const configJSON = require('./config')
import {preview} from "../../../websitebuilder/src/assets"

class NavComponent extends NavController {
    constructor(props: any) {
        super(props);
        // @ts-ignore
        this.state = {
            // @ts-ignore
            adminuser: null,
            loading: true,
            links: [
                {
                    id: 'home',
                    label: 'Home',
                    iconName: 'icon-union',
                    link: '/Home'
                },
                {
                    id: 'customer',
                    label: 'Customers',
                    iconName: 'icon-customer',
                    link: '/Customers'
                },
                {
                    id: 'sales',
                    label: 'Sales',
                    iconName: 'icon-orders',
                    subLinks: [
                        {
                            id: 'order',
                            label: 'Orders',
                            link: '/Orders'
                        },
                        {
                            id: 'reviews',
                            label: 'Reviews',
                            link: '/Reviews'
                        },
                    ]
                },
                {
                    id: 'menu',
                    label: 'Menu',
                    iconName: 'icon-products',
                    subLinks: [
                        {
                            id: 'items',
                            label: 'Items',
                            link: '/InventoryManagement'
                        },
                        {
                            id: 'categories',
                            label: 'Categories',
                            link: '/Categories'
                        },
                        {
                            id: 'variants',
                            label: 'Variants',
                            link: '/Variants'
                        }
                    ]
                },
                {
                    id: 'website-email',
                    label: 'Website & emails',
                    iconName: 'icon-website',
                    subLinks: [
                        {
                            id: 'branding',
                            label: 'Branding',
                            link: '/AdminBranding'
                        },
                        {
                            id: 'image',
                            label: 'Image Catalogue',
                            link: '/AdminImageCatalogue'
                        },
                        {
                            id: 'email setting',
                            label: 'Email Templates',
                            link: '/AdminEmailTemplates'
                        },
                        {
                            id: 'static',
                            label: 'Static Pages',
                            link: '/AdminStaticPage'
                        },


                        // {
                        //     id: 'coustomer',
                        //     label: 'Customer Feedback',
                        //     link: '/AdminCustomerFeedback'
                        // }
                    ]
                },
                {
                    id: 'business',
                    label: 'Business Settings',
                    iconName: 'icon-settings',
                    subLinks: [
                        {
                            id: 'store',
                            label: 'Store details',
                            link: '/AdminStoreDetails'
                        },
                        {
                            id: 'tax',
                            label: 'Taxes',
                            link: '/AdminTaxes'
                        },
                        {
                            id: 'shipping',
                            label: 'Delivery',
                            link: '/AdminShipping'
                        },
                        // {
                        //     id: 'promoCode',
                        //     label: 'Marketing',
                        //     link: '/ShareStoreTab'
                        // },
                        {
                            id: 'paymentIntegration',
                            label: 'Payment',
                            link: '/AdminPaymentIntegration'
                        },
                        {
                            id: 'mobile_app',
                            label: 'Mobile App',
                            link: '/AdminMobileApp'
                        },
                        // {
                        //     id: 'notifications',
                        //     label: 'Notifications',
                        //     link: '/AdminNotifications'
                        // },
                        {
                            id: 'otherIntegrations',
                            label: 'Other Integrations',
                            link: '/AdminOtherIntegrations'
                        }
                    ]
                },
                {
                    id: 'marketing',
                    label: 'Marketing',
                    iconName: 'icon-advertising-megaphone',
                    subLinks: [
                        {
                            id: 'seo',
                            label: 'SEO',
                            link: '/seo-checklist'
                        },
                        {
                            id: 'rewards',
                            label: 'Rewards',
                            link: '/AdminRewards'
                        },
                        {
                            id: 'leads',
                            label: 'New Leads',
                            link: '/AdminMarketing'
                        },
                        {
                            id: 'promotions',
                            label: 'Promotions',
                            link: '/ShareStoreTab'
                        },

                    ]
                },
                {
                    id: 'account',
                    label: 'Account',
                    iconName: 'icon-user',
                    subLinks: [
                        {
                            id: 'profile',
                            label: 'Users',
                            link: '/AdminAccount'
                        },
                    ]
                },

            ],
            tierType: "",
        }
    }

    async componentDidMount() {
        const token = await getStorageData("admintoken");
        const adminUser = await getStorageData("adminuser");
        if (token && adminUser) {
            this.setState({
                adminuser: JSON.parse(adminUser) || {},
                loading: false
            })
        }
    }
    render() {
        // @ts-ignore
        const adminuser = this.state.adminuser;
        const permissions = adminuser?.data?.attributes?.permissions;
        const permissionLinks = (data: any) => {
            if (data?.id && data?.subLinks) {
                const value1 = [];
                data?.subLinks?.map((values: any) => permissions?.find(permission => {
                    if (permission === values?.id) {
                        value1?.push(values)
                        return values
                    }
                }))
                data.subLinks = value1;
                return data?.subLinks?.length ? data : undefined;
            }
            if (data) {
                const value2 = [];
                permissions?.find(permission => {
                    if (permission === data?.id) {
                        value2?.push(data)
                        return data
                    }
                })
                return value2[0];
            }

        }
        // @ts-ignore
        return (
            <NavContainer
                className='admin-nav'
                tabs
                vertical
            >
                {permissions && this.state?.links.length &&
                    this.state?.links.map((data: any, index: number) => (
                        permissions.includes("all")
                            ? <RenderLink data={data} tierType={this.state.tierType} />
                            : (index === 0 && permissions.includes("order")) ? <RenderLink data={this.state.links[0]} tierType={this.state.tierType} />
                                : permissionLinks(data) !== undefined
                                    ? <RenderLink data={permissionLinks(data)} tierType={this.state.tierType} /> : ''
                    ))
                }
                <a href="/preview" style={{textDecoration:'none', cursor:'pointer'}} target="_blank">
                <div style={{padding:'0.45rem 0.5rem', marginTop:'24px'}}>
                    <div className=""
                        style={{ border: "1px solid #5500CC", color:'#5500CC',padding: '11px', borderRadius: '4px', display: 'flex', justifyContent: 'center',gap:'8px',alignItems:'center', fontFamily:'Rubik', fontWeight:500, fontSize:'14px', lineHeight:'22px' }}
                    >
                        <img src={preview} alt="" style={{height:'16px', width:'16px'}} />
                        Preview Website
                    </div>
                </div>
                </a>
            </NavContainer>
        );

    }


}

export const Nav = withToast(NavComponent);
// Customizable Area End
